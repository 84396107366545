/* ################################################################### */
/* FUNCTION (STICKY HEADER) ########################################## */
/* ################################################################### */
function stickyHeader()
{
	if(window.pageYOffset > 0)
		jQuery('#header').addClass('is-sticky');

	window.onscroll = function()
	{
		if(window.pageYOffset > 0)
			jQuery('#header').addClass('is-sticky');
		else
			jQuery('#header').removeClass('is-sticky');
	}
}
