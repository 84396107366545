/* ######################################################################################################################## */
/* FUNCTION (FORM VALIDATION) ############################################################################################# */
/* ######################################################################################################################## */
function formValidation(speed, ease)
{
	jQuery('form').each(function()
	{
		var jQuerythis = jQuery(this);

		if(jQuerythis.hasClass('js-browser-validation'))
			jQuerythis.attr('novalidate', 'novalidate');
	});

	jQuery('input, select, textarea').not('.js-input-novalidate').change(function()
	{
		var jQuerythis = jQuery(this);

		if(jQuerythis.parents('form').hasClass('js-browser-validation'))
			jQuerythis.validate();
	});

	jQuery('form').submit(function(e)
	{
		var jQuerythis = jQuery(this);

		jQuerythis.find('.input-submit').addClass('is-submitting');

		if(jQuerythis.hasClass('js-browser-validation'))
		{
			jQuerythis.find('input, select, textarea').validate();

			if(jQuerythis.find('.js-field.is-invalid').length)
			{
				e.preventDefault();

				jQuerythis.find('.js-alert.is-invalid').slideDown(speed, ease);

				setTimeout(function()
				{
					jQuery('.js-modal_wrapper').each(function()
					{
						var idModal = '#' + jQuery(this).attr('id');

						if(jQuery(idModal).hasClass('open'))
							document.body.style.overflow = "hidden";

						checkModalHeight(idModal);
					});
				}, speed);

				jQuery('.is-invalid', jQuerythis).find('input, select, textarea').first().focus();
				jQuerythis.find('.input-submit').removeClass('is-submitting');
			}
			else
			{
				jQuerythis.find('.js-alert.is-invalid').slideUp(speed, ease);

				jQueryrecaptcha = jQuerythis.find('.g-recaptcha');

				if(jQueryrecaptcha.length)
				{
					e.preventDefault();

					var recaptchaId    = jQueryrecaptcha.data('recaptcha-id');
					currentRecaptchaId = recaptchaId;

					if(typeof grecaptcha === 'object')
					{
						grecaptcha.execute(recaptchaId);
					}
				}
				else
				{
					this.submit();
				}
			}
		}
	});
}

/* ######################################################################################################################## */
/* FUNCTION (VALIDATE FIELDS) ############################################################################################# */
/* ######################################################################################################################## */
jQuery.fn.validate = function()
{
	return jQuery(this).each(function()
	{
		var jQfield         = jQuery(this).closest('.js-field'),
			jQfieldFeedback = jQfield.find('.field-feedback'),
			validity 		= jQuery(this).prop('validity');

		jQfield.removeClass('is-valid is-invalid');

		if(validity.valid === true)
		{
			jQfield.addClass('is-valid');
		}
		else if(validity.valid === false)
		{
			var validationMsg = jQfieldFeedback.data('validation-msg');

			if(!jQfieldFeedback.data('validation-msg'))
			{
				var message = 'There is a problem with the data inputted';

				if(validity.valueMissing === true)
					message = 'This is a required field';

				else if(validity.typeMismatch === true && jQuery(this).attr('type') === 'email')
					message = 'Please enter a valid email address';

				else if (validity.typeMismatch === true && jQuery(this).attr('type') === 'url')
					message = 'Please enter a valid website address';

				else if (validity.tooShort === true)
					message = 'Must be a minimum of ' + jQuery(this).attr('minlength') + ' characters';

				else if (validity.tooLong === true)
					message = 'Must be a maximum of ' + jQuery(this).attr('maxLength') + ' characters';

				jQfieldFeedback.html('<span>' + message + '</span>');
			}
			else
			{
				jQfieldFeedback.html('<span>' + validationMsg + '</span>');
			}

			jQfield.addClass('is-invalid');
		}
	});
};

/* ######################################################################################################################## */
/* FUNCTION (AJAX FORM SUBMITS) ########################################################################################### */
/* ######################################################################################################################## */
var ajaxSubmit = function(jQueryform)
{
	if(jQueryform instanceof jQuery !== true)
		jQueryform = jQuery(jQueryform);

	jQueryform.find('.js-btn-ajax').addClass('is-loading');

	jQuery('#alert').fadeOut(s.animSpeed, function()
	{
		jQuery('#alert').remove();
	});

	jQuery.post(
	{
		url      : jQueryform.attr('action'),
		data     : jQueryform.serialize(),
		dataType : 'html',
		success  : function(response, status)
		{
			jQueryform.find('.js-btn-ajax').removeClass('is-loading');

			var jQueryhtml  = jQuery('<html></html>').append(jQuery(response));
			var jQueryalert = jQueryhtml.find('#alert');

			jQueryalert.clone().css('opacity', 0).appendTo('body').animate(
			{
				opacity: 1
			}, s.animSpeed);

			if(jQueryalert.hasClass('alert-valid'))
			{
				jQueryform[0].reset();
				jQueryform.find('.js-field').removeClass('is-valid');
			}
			else
			{
				var jQuerynewForm = jQueryhtml.find('#' + jQueryform.attr('id'));

				jQuerynewForm.find('.js-field.is-invalid').each(function(i)
				{
					var jQueryoldField = jQueryform.find('.js-field:eq(' + i + ')');

					jQueryoldField.find('.js-field-feedback').text(jQuery(this).find('.js-field-feedback').text());
					jQueryoldField.addClass('is-invalid');
				});

				jQueryform.find('.js-field.is-invalid:first').find('.js-field-control').focus();
			}
		}
	});
};

window.ajaxSubmit = ajaxSubmit;
