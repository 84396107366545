/* ######################################################################################################################## */
/* FUNCTION (ACCORDION) ################################################################################################### */
/* ######################################################################################################################## */
function accordion(speed, ease)
{
	jQuery('.js-accordion').each(function()
	{
		var jQthis = jQuery(this);
		var isChecked = false;

		jQuery('input', this).each(function()
		{
			if(jQuery(this).prop('checked'))
			{
				isChecked = true;
			}
		});

		if(isChecked)
		{
			var jQblock = jQthis.find('.js-accordion_block');
			openAccordion(jQblock);
		}
	});

	jQuery('.js-accordion_block.open').each(function()
	{
		jQuery('.js-accordion_container', this).show();
	});

	jQuery('.js-accordion_trigger').on('click', function(e)
	{
		e.stopPropagation();
		jQuery(this).blur();

		var jQthis      = jQuery(this);
			jQaccordion = jQthis.parents('.js-accordion');
			jQblock     = jQthis.parents('.js-accordion_block');

		if(!jQblock.hasClass('open'))
		{
			jQblock
				.addClass('open')
				.find('.js-accordion_container').slideDown(speed, ease);
		}
		else
		{
			jQblock
				.removeClass('open')
				.find('.js-accordion_container').slideUp(speed, ease);
		}
	});
}

function openAccordion(jQblock)
{
	jQblock
		.addClass('open')
		.find('.js-accordion_container').slideDown(speed, ease);
}

function closeAccordion(jQblock)
{
	jQblock
		.removeClass('open')
		.find('.js-accordion_container').slideUp(speed, ease);
}
