/* ######################################################################################################################## */
/* SOCIAL FEED ############################################################################################################ */
/* ######################################################################################################################## */
function socialFeed(initShow)
{
	// Isotope INIT
	var jQgrid = jQuery('.masonry_grid', '.social-feed').isotope(
	{
		itemSelector : '.masonry_item'
	});

	// Filters INIT
	jQuery('.social-feed_filters', '.social-feed').on('click', 'li', function()
	{
		var filterValue = jQuery(this).attr('data-filter');

		jQgrid.isotope({ filter : filterValue });
	});

	jQuery('.social-feed_filters', '.social-feed').each(function(i, filters)
	{
		var jQfilters = jQuery(filters);

		jQfilters.on('click', 'li', function()
		{
			jQfilters.find('.is-active').removeClass('is-active');
			jQuery(this).addClass('is-active');

			loadMore(initShow);
		});
	});

	// Load more INIT
	var initShow = initShow;
		counter  = initShow;
		iso      = jQgrid.data('isotope');

	loadMore(initShow);

	function loadMore(toShow)
	{
		jQgrid.find('.hidden').removeClass('hidden');

		var hiddenElems = iso.filteredItems.slice(toShow, iso.filteredItems.length).map(function(item)
		{
			return item.element;
		});

		jQuery(hiddenElems).addClass('hidden');
		jQgrid.isotope('layout');

		if(hiddenElems.length == 0)
			jQuery('#social-feed_load-more').parent().hide();
		else
			jQuery('#social-feed_load-more').parent().show();
	}

	jQuery('#social-feed_load-more').click(function()
	{
		jQuery(this).blur();

		if(jQuery('#social-feed_filters').data('clicked'))
		{
			counter = initShow;
			jQuery('#social-feed_filters').data('clicked', false);
		}
		else
			counter = counter;

		counter = counter + initShow;
		loadMore(counter);
	});
}
