/* ######################################################################################################################## */
/* FUNCTION (FLASH MESSAGE) ############################################################################################### */
/* ######################################################################################################################## */
function flashMessage(speed, ease)
{
	jQuery('.js-flash-message_close').on('click', function(e)
	{
		e.preventDefault();
		e.stopPropagation();

		var hide_messages = true;

		jQuery(this)
			.blur()
			.parents('.js-flash-message').addClass('is-dismissed');

		jQuery('.js-flash-message:not(.is-dismissed)', jQuery(this).parents('.js-flash-messages')).each(function()
		{
			hide_messages = false;
		});

		if(hide_messages)
		{
			setTimeout(function()
			{
				jQuery('.js-flash-messages').addClass('is-all-dismissed');
			}, 600);
		}
	});
}
