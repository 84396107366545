Sentry.init({
    dsn: 'https://c37bd2b2fa47418d94222dc02b8bf0b6@sentry.io/1504864',
    integrations: [
        new Sentry.Integrations.GlobalHandlers({
            onerror: false,
            onunhandledrejection: false,
        }),
    ],
    beforeSend: function(event, hint) {
        if (!event || !hint.originalException || !hint.originalException.stack) {
            return false;
        }

        var message = hint.originalException.stack;

        if (message.match(/maddermarket\.co\.uk\/wp-content\/themes\/maddermarket\/assets\/js/)) {
            return event;
        }
    },
});

/* ######################################################################################################################## */
/* GENERIC VARS ########################################################################################################### */
/* ######################################################################################################################## */
var speed     = 300;
var	ease      = 'easeInOutQuart';
var timer_out = 0;

/* ######################################################################################################################## */
/* SPEKTRIX ############################################################################################################### */
/* ######################################################################################################################## */

// Spektrix ScrollToTop
if($('.js-onload-scrolltotop').length)
{
    $('.js-onload-scrolltotop').load(function()
    {
    	var $this = $(this);

    	setTimeout(function()
    	{
    		var offsetTop = $this.parents('.box').offset().top;
    		var headerH   = $('#header').outerHeight(true);

    		$('html, body').animate(
    		{
    			scrollTop : offsetTop - headerH
    		}, speed * 2, ease);
    	}, 100)
    });
}

/* ######################################################################################################################## */
/* SCRIPTS (WINDOW ONLOAD) ################################################################################################ */
/* ######################################################################################################################## */
$(window).load(function()
{
	if($('.js-social-feed').length)
	{
		var loadedSocialFeed = false;
		var $socialFeed = $('.js-social-feed');

		/*
		if(!loadedSocialFeed && elementInViewport($socialFeed))
		{
			ajaxLoadSocialFeed();
		}

		$(window).scroll(function()
		{
			if(!loadedSocialFeed && elementInViewport($socialFeed))
			{
				ajaxLoadSocialFeed();
			}
		});

		function ajaxLoadSocialFeed()
		{
			$.get($socialFeed.data('url'), {}, function(html)
			{
				$socialFeed.append(html);
				$socialFeed.imagesLoaded(function () {
					$socialFeed.isotope('reloadItems').isotope();
				});
			}).always(function()
			{
			});

			loadedSocialFeed = true;
		}

		function elementInViewport(e)
		{
			return $(window).scrollTop() + $(window).height() > e.offset().top
		}
		*/

		socialFeed(9);
	}

	// Spektrix iFrame resizer
    /*
	if($('.spektrix-iframe').length)
	{
		$('.spektrix-iframe').iFrameResize();
	}
    */

	// Scroll to "Select a Performance"
    if($('#page-booking').length)
	{
    	if(window.location.href.indexOf('#select-a-performance') > 0)
    	{
    		var offsetTop = $('#page-booking').offset().top;
    		var headerH   = $('#header').outerHeight(true);

    		$('html, body').animate(
    		{
    			scrollTop : offsetTop - headerH
    		}, speed * 2, ease);
    	}
    }

    // Sidebar
	if($('.js-sidebar').length)
	{
		$('.wrapper').addClass('page-has-sidebar');

		var stickySidebar = new StickySidebar('.js-sidebar',
		{
			topSpacing   : $('#header').outerHeight(true) + 48,
			minWidth     : 768
		});
	}
});

/* ######################################################################################################################## */
/* SCRIPTS (DOCUMENT READY) ############################################################################################### */
/* ######################################################################################################################## */
$(document).ready(function()
{
	accordion(speed, ease);      // Accordion
	panel();                     // Panel
	modalWindow(speed, ease);    // Modal window
	flashMessage(speed, ease)    // Flash message
	stickyHeader();              // Sticky header
	formValidation(speed, ease); // Form validation

	// Default anchor behaviour
	$('a[href="#"]').on('click', function(e)
	{
		e.preventDefault();
	});

	// goTo ID
	$('body').delegate('.js-goTo', 'click', function(e)
	{
		e.preventDefault();
		$(this).blur();

		var idDiv     = $(this).attr('href');

		if (!$(idDiv).length) {
			return false;
		}

		var offsetTop = $(idDiv).offset().top;
		var headerH   = $('#header').outerHeight(true);

		$('html, body').animate(
		{
			scrollTop : offsetTop - headerH
		}, speed * 2, ease);
	});

	// Header navigation
	$('.nav-header_list-item_link-chevron').on('mouseover', function()
	{
		$(this).parents('.nav-header_list-item').addClass('submenu-is-open');
	})
	$('.nav-header_list-item')
		.on('mouseover', function()
		{
			$('.nav-header_list-item.submenu-is-open').not(this).removeClass('submenu-is-open');
			clearTimeout(timer_out);
		})
		.on('mouseleave', function()
		{
			var $this = $(this);
			timer_out = setTimeout(function()
			{
				$this.removeClass('submenu-is-open');
			}, speed / 2);
		});

	// Mobile Main Navigation (accordion)
	$('body').delegate('.nav-header_list-item_link-chevron', 'click', function(e)
	{
		var $thisParent = $(this).parent();

		if($('#panel-nav-primary-mobile').hasClass('is-active'))
		{
			$thisParent
				.toggleClass('is-submenu-close')
				.next().slideToggle(speed, ease);
		}
	});

	// Slick
	$('.js-slick').each(function()
	{
		if($('.js-slick_slide', this).length > 0)
			$(this).slick().addClass('is-visible');
	});

	// Gallery
	$('.js-modal-gallery_trigger').on('click', function(e)
	{
		var $this        = $(this);
		var galleryID    = '#slick-' + $this.attr('data-modal').substring(1);
		var gallerySlide = $this.attr('data-slide');

		$(galleryID).slick('slickGoTo', gallerySlide - 1);
	});

	$('.modal-gallery_btn').on('click', function(e)
	{
		e.preventDefault();
		e.stopPropagation();
	});

	// Input Date
	$('.input-datepicker').flatpickr(
	{
		mode       : 'range',
		dateFormat : 'd-m-Y',
		wrap       : true,
		onClose    : function(selectedDates, dateStr, instance)
		{
			if(selectedDates.length == 1)
			{
				instance.setDate([selectedDates[0],selectedDates[0]], true);
			}
		}
	});
	/*
	$('#product-filters-datefrom').on('change', function()
	{
		$('#product-filters-dateto').attr('min', $(this).val());
	});
	*/

	// Table Wrapper (Typography)
	$('table', '.typography').wrap('<div class="typography-table_wrapper"></div>');

	// Form "onchange" hook
	$('.js-onchange-submit :input').on('change', function()
	{
		$(this).closest('form').submit();
	});

	// History Back
	$('body').delegate('.js-history-back', 'click', function(e)
	{
		window.history.back();
	});

	// AJAX loading for sorting
	/*
	$('body').delegate('.js-ajax-form', 'submit', function(e)
	{
		e.preventDefault();

		var $this    = $(this),
			url      = $(location).attr('href'),
			data     = $this.serialize();

		$('body').addClass('is-ajax-loading');

		$.get(url, data, function(data)
		{
			$('.js-ajax-content').html($('.js-ajax-content', data));

			$('body').removeClass('is-ajax-loading');
		});
	});
	*/
});

/* ######################################################################################################################## */
/* SCRIPTS (WINDOW RESIZE) ################################################################################################ */
/* ######################################################################################################################## */
$(window).bind('resizeEnd', function()
{
	panelReset(); // Reset panels
});

// resizeEnd
$(window).resize(function()
{
	if(this.resizeTO)
		clearTimeout(this.resizeTO);

	this.resizeTO = setTimeout(function()
	{
		$(this).trigger('resizeEnd');
	}, 100);
});

/* ######################################################################################################################## */
/* SCRIPTS (WINDOW SCROLL) ################################################################################################ */
/* ######################################################################################################################## */
$(window).scroll(function()
{
	var top = $(this).scrollTop() + $('#header').outerHeight(true);


	$('.js-goTo', '.js-sidebar').each(function()
	{
		var jQthis   = $(this);
		var jQparent = $(this).parent();
		var innerTop = $(jQthis.attr('href')).offset().top - 2;

		if(innerTop <= top)
		{
			jQparent
				.addClass('current-menu-item')
				.siblings().removeClass('current-menu-item');
		}
		else
		{
			jQparent.removeClass('current-menu-item')
		}
	});
});

/* ######################################################################################################################## */
/* FUNCTIONS (LOCK / UNLOCK SCROLL) ####################################################################################### */
/* ######################################################################################################################## */
function lockScroll()
{
	$('html').attr('data-scroll-current', $(window).scrollTop());
	window.addEventListener('scroll', noscroll);
}

function unlockScroll()
{
	window.removeEventListener('scroll', noscroll);
}

function noscroll()
{
	$(window).scrollTop($('html').attr('data-scroll-current'));
}

$(function()
{
    objectFitImages();
});
