/* ######################################################################################################################## */
/* FUNCTION (MODAL WINDOW) ################################################################################################ */
/* ######################################################################################################################## */
function modalWindow(speed, ease)
{
	jQuery('.js-modal_trigger').on('click', function(e)
	{
		e.preventDefault();
		e.stopPropagation();

		var jQuery_this = jQuery(this);
			idModal     = jQuery_this.attr('data-modal');

		jQuery_this.blur();
		toggleModalwindow(idModal);
	});

	jQuery(document)
		.on('click', function(e)
		{
			if(jQuery('.js-modal_wrapper.open').length > 0)
			{
				if(jQuery(e.target).closest('.js-modal_content').length === 0)
					closeModalwindows();
			}
		})
		.keyup(function(e)
		{
			if(jQuery('.js-modal_wrapper.open').length > 0)
			{
				if(e.keyCode == 27)
					closeModalwindows();
			}
		});
}

/* ######################################################################################################################## */
/* FUNCTION (TOGGLE MODAL WINDOW) ######################################################################################### */
/* ######################################################################################################################## */
function toggleModalwindow(idModal)
{
	var speed = '';
		ease  = '';

	// document.body.style.overflow = "hidden";
	lockScroll();

	if(jQuery(idModal).hasClass('open'))
	{
		// document.body.style.overflow = "";
		unlockScroll();
		jQuery(idModal).removeClass('open');
	}
	else
	{
		jQuery(idModal).addClass('open');
		// document.body.style.overflow = "hidden";
		lockScroll();
	}

	checkModalHeight(idModal);

	jQuery(window).bind('resizeEnd', function(){ checkModalHeight(idModal) });
}

/* ######################################################################################################################## */
/* FUNCTION (CLOSE MODAL WINDOW) ########################################################################################## */
/* ######################################################################################################################## */
function closeModalwindows()
{
	// document.body.style.overflow = "";
	unlockScroll();
	jQuery('.js-modal_wrapper').removeClass('open');
}

/* ######################################################################################################################## */
/* FUNCTION (CHECK MODAL HEIGHT) ########################################################################################## */
/* ######################################################################################################################## */
function checkModalHeight(idModal)
{
	var thisModal     = jQuery(idModal);
		modalContent  = jQuery('.js-modal_content', idModal);
		hModalContent = modalContent.outerHeight(true);
		hBody         = jQuery(window).height();

	if(hModalContent >= hBody)
		thisModal.addClass('fixedHeight');
	else
		thisModal.removeClass('fixedHeight');
}
