/* ######################################################################################################################## */
/* FUNCTION (PANEL) ####################################################################################################### */
/* ######################################################################################################################## */
function panel()
{
	$('body').delegate('.js-panel_trigger', 'click', function(e)
	{
		var $this  = $(this);
		var panel  = '#' + $this.attr('data-panel');

		$this.blur();

		if($('.js-panel').not(panel).hasClass('is-active'))
		{
			unlockScroll();
			$('.js-panel').not(panel).removeClass('is-active');
		}

		$(panel).toggleClass('is-active');

		if($('.js-panel').hasClass('is-active'))
			lockScroll();
		else
			unlockScroll();
	});

	$(document)
		.mouseup(function(e)
		{
			if(jQuery('.js-panel.is-active').length > 0)
			{
				var container  = $('.js-panel-container');
				var trigger    = $('.js-panel_trigger');
				var datepicker = $('.flatpickr-calendar');

				if((!container.is(e.target) && container.has(e.target).length === 0) && (!trigger.is(e.target) && trigger.has(e.target).length === 0) && (!datepicker.is(e.target) && datepicker.has(e.target).length === 0))
				{
					unlockScroll();
					$('.js-panel').removeClass('is-active');
				}
			}
		})
		.keyup(function(e)
		{
			if(jQuery('.js-panel.is-active').length > 0)
			{
				if(e.keyCode == 27)
				{
					unlockScroll();
					$('.js-panel').removeClass('is-active');
				}
			}
		});;
}

function panelReset()
{
	if(jQuery(window).width() > 768)
	{
		if($('.js-panel').hasClass('is-active'))
		{
			unlockScroll();
			$('.js-panel').removeClass('is-active');
		}
	}
}
